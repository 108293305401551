// Here you can add other styles

.avatar {
  height: 45px;
  width: 45px;
}

.cardtitle {
  font-size: 18px;
}

.switchoverride .c-switch-slider::before {
  z-index: 0;
}

.switchoverride .c-switch-slider::after {
  z-index: 0;
}

.bgred {
  background-color: rgb(255, 245, 231);
}

.multiSelect {
  height: 15px;
  width: 15px;
}

.walletAmount {
  font-size: 16px;
  font-weight: 700;
}

.txt {
  font-size: 14px;
  font-weight: 600;
}

.eyeIcon {
  height: 20px;
  width: 20px;
}

.field-input {
  color: black;

  &:focus {
    color: black;
  }
}

.field-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.w-55 {
  width: 55px;
}

.table-revamp thead tr th {
  div {
    width: 90px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(2) div {
    width: 170px;
  }
}

.orders thead tr th {
  div {
    width: 90px;
    display: block !important;
  }
  &:nth-child(1) div {
    width: 8px;
  }
  &:nth-child(2) div {
    width: 240px;
  }
  &:nth-child(3) div {
    width: 60px;
  }
  &:nth-child(4) div {
    width: 80px;
  }
  &:nth-child(5) div {
    width: 40px;
  }
  &:nth-child(6) div {
    width: 60px;
  }
  &:nth-child(7) div {
    width: 70px;
  }
  &:nth-child(8) div {
    width: 380px;
  }
  &:last-child div {
    width: 140px;
  }
}

.taxes thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(5) div {
    width: 170px;
  }
  &:nth-child(6) div {
    width: 170px;
  }
}

.default thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(2) div {
    width: 180px;
  }
  &:nth-child(3) div {
    width: 50px;
  }
  &:last-child div {
    width: 180px;
  }
}

.users thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(2) div {
    width: 230px;
  }
  &:nth-child(3) div {
    width: 50px;
  }
  &:last-child div {
    width: 180px;
  }
}

.detailed-sub-cat thead tr th {
  div {
    display: block !important;
  }
  &:first-child div {
    width: 30px;
  }
  &:nth-child(2) div {
    width: 190px;
  }
  &:nth-child(3) div {
    width: 50px;
  }
  &:nth-child(4) div {
    width: 200px;
  }
  &:nth-child(5) div {
    width: 160px;
  }
  &:nth-child(6) div {
    width: 160px;
  }
  &:last-child div {
    width: 180px;
  }
}

.exchange-return thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(2) div {
    width: 200px;
  }
  &:nth-child(3) div {
    width: 110px;
    display: block !important;
  }
  &:nth-child(7) div {
    width: 250px;
  }
  &:last-child div {
    width: 180px;
  }
}

.add-to-cart thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 80px;
  }
  &:nth-child(2) div {
    width: 50px;
  }
  &:nth-child(4) div {
    width: 50px;
  }
  &:nth-child(5) div {
    width: 75px;
  }
  &:nth-child(6) div {
    width: 75px;
  }
}

.products thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 40px;
  }
  &:nth-child(2) div {
    width: 180px;
  }
  &:nth-child(3) div {
    width: 70px;
  }
  &:nth-child(4) div {
    width: 50px;
  }
  &:nth-child(5) div {
    width: 220px;
  }
  &:nth-child(6) div {
    width: 100px;
  }
  &:last-child div {
    width: 200px;
  }
}

.product-variants thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 20px;
  }
  &:nth-child(2) div {
    width: 80px;
  }
  &:nth-child(3) div {
    width: 70px;
  }
  &:nth-child(4) div {
    width: 40px;
  }
  &:nth-child(5) div {
    width: 50px;
  }
  &:nth-child(6) div {
    width: 50px;
  }
}

.add-edit-exchange thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:first-child div {
    width: 20px;
  }
  &:nth-child(3) div {
    width: 200px;
  }
}

.orders-details-products thead tr th {
  div {
    width: 110px;
    display: block !important;
  }
  &:nth-child(1) div {
    width: 230px;
  }
  &:nth-child(2) div {
    width: 60px;
  }
  &:nth-child(3) div {
    width: 80px;
  }
  &:nth-child(4) div {
    width: 140px;
  }
  &:nth-child(5) div {
    width: 135px;
  }
  &:nth-child(6) div {
    width: 135px;
  }
  &:nth-child(7) div {
    width: 120px;
  }
}

.size-guide-table {
  border-collapse: collapse;

  td,
  thead {
    border: 1px solid black;
  }
}

.customDropzone {
  border: 1px dotted #9c27b0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.image-item {
  display: flex;
  margin: 10px 0;
}
.upload-image-area-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
.upload-image-area {
  display: flex;
  align-items: center;
  height: 100px;
  background: rgb(248, 248, 248);
  justify-content: center;
  border-style: dashed;
  border-color: #007bff;
  border-width: 1px;
  cursor: pointer;
}
.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.file_upload {
  margin-left: 10px;
  margin-bottom: 0;
  border: 1px solid #321fdb;
  padding: 6px;
  border-radius: 4px;
  color: #321fdb;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #321fdb;
  }
}

.resetButton {
  margin-top: 25px;
}
